/* eslint-disable no-undef */
import { isNaN } from 'lodash'

var process = []
var calledUrls = []

const loadingWrapper = function (color = 'primary', multiple = 1, mx = 0, mt = 0, type = null) {
  let wrapper = ''
  let marginClass = (mx === 0 || mt === 0) ? 'm-auto' : 'mx-' + mx + ' mt-' + mt
  if (mx !== 0) {
    marginClass = 'my-auto mx-' + mx
  }
  if (mt !== 0) {
    marginClass = 'mx-auto mt-' + mt
  }

  let colorTxt = ''
  if (type == null) {
    for (var i = 0; i < multiple; i++) {
      if (Array.isArray(color)) {
        colorTxt = color[Math.floor(Math.random() * Math.floor(color.length))]
      } else {
        colorTxt = color
      }

      wrapper += `
        <div class="spinner-grow tools-loadingWrapper spinner-grow-sm ` + marginClass + ` bg-` + colorTxt + `">
        </div>
      `
    }
  }

  if (type === 'spinner') {
    if (Array.isArray(color)) {
      colorTxt = color[Math.floor(Math.random() * Math.floor(color.length))]
    } else {
      colorTxt = color
    }
    wrapper = `
    <div class="col-12 my-3"><div class="row justify-content-center">
      <div class="spinner-border text-` + colorTxt + ` ` + marginClass + `"></div>
    </div></div>`
  }

  return wrapper
}

const updateBrowserHistory = function (newUrl) {
  window.history.pushState({
    html: document.documentElement.innerHTML,
    pageTitle: 'Test',
    'refresh-ajax': true
  }, 'titre', newUrl)
}

const loadContent = function (container, url, inModal, withBigLoading = false, bigLoadingMessage = null) {
  // tools.toolsDebug('tools::loadContent() fired')
  // tools.toolsDebug('calledUrls length : <b>' + calledUrls.length + '</b>')
  // tools.toolsDebug('current called url : <b>' + url + '</b>')

  if ($(container).hasClass('jx-loading-in-progress')) {
    // tools.toolsDebug('loadContent() called on container with <b>jx-loading-in-progress</b> class, demand aborded')
    return
  }
  $(container).addClass('jx-loading-in-progress')

  if (calledUrls.includes(url)) {
    // tools.toolsDebug(url + ' is already in calledUrls process', 'danger')
    return
  }

  if (withBigLoading === true) {
    plan.loader(bigLoadingMessage)
  } else {
    $(container).html(tools.loadingWrapper(['menu', 'yellow', 'red', 'gray', 'd-blue', 'peach'], 5, 0, 0, 'spinner'))
  }
  url = url.trim()
  // console.log(container, url)
  $(container).load(url, function () {
    $(container).removeClass('jx-loading-in-progress')
    if (!$(container).is(':visible') && !$(container).hasClass('disable-auto-hide')) {
      $(container).show('slow')
    }

    if ($(container).children('.search').length > 0) {
      // console.log('autofocus')
      $(container).children('.search').trigger('focus')
    }

    // console.log('init launched')
    tools.init()
  })
}

const numberWithSpace = function (x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

const addToProcess = function (id) {
  // tools.toolsDebug('tools::addToProcess() fired')
  // tools.toolsDebug('add to process : <b>' + id + '</b>')
  process.push(id)
  // tools.toolsDebug('process length : <b>' + process.length + '</b>')
}

const isInProcess = function (id) {
  return process.indexOf(id)
}

const autoloading = function () {
  const $loadings = $('body .autoloading')

  if ($loadings.length <= 0) {
    return
  }
  let loadingLength = 0

  $loadings.each(function (i, elm) {
    const $loading = $(elm)

    if ($loading.hasClass('autoloading-initialized')) {
      return
    }

    const elmId = $loading.attr('id')

    if (elmId === undefined) {
      // tools.toolsDebug('tools::autoloading() the loading elm need an id', 'warning')

      return
    }

    $loading.addClass('autoloading-initialized')

    let url = $loading.data('url')
    const container = $loading.data('container')

    $('body #' + container).append(`
            <div class="col-12">
                <i class="fal fa-circle-notch fa-spin"></i>
                Traitement des données en cours, merci pour votre patience
            </div>
        `)

    if (url === undefined) {
      if (!$loading.hasClass('form-control')) {
        return
      }

      const $selectedOption = $loading.find(':selected')

      if ($selectedOption.length <= 0) {
        return
      }

      url = $selectedOption.data('url')

      if (url === undefined) {
        return
      }
    }

    if (container === undefined) {
      return
    }

    loadingLength += 1000

    setTimeout(function () {
      tools.loadContent($('body #' + container), url)
    }, loadingLength)
  })
}

const init = function () {
  tools.autoloading()
}

const generateRgba = function (alpha = 1) {
  if (alpha > 1) {
    alpha = 1
  }

  if (alpha < 0) {
    alpha = 0
  }

  const r = Math.floor(Math.random() * Math.floor(255))
  const g = Math.floor(Math.random() * Math.floor(255))
  const b = Math.floor(Math.random() * Math.floor(255))

  return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')'
}

const confirm = function (options, onValidation, onCancel) {
  var defaults = {
    title: 'Veuillez confirmer votre action',
    closeIcon: true,
    content: '',
    type: 'red',
    buttons: {
      Annuler: {
        btnClass: 'btn btn-red',
        action: onCancel
      },
      Confirmer: {
        btnClass: 'btn btn-aqua-green',
        action: onValidation
      }
    }
  }

  // console.log('$.confirm()')

  var settings = $.extend({}, defaults, options)
  // console.log(settings)
  $.confirm(settings)
}

const getRandomInt = function (min = null, max = null) {
  min = (min != null) ? Math.ceil(min) : Date.now()
  max = (max != null) ? Math.floor(max) : (Date.now() * 2)

  return Math.floor(Math.random() * (max - min + 1)) + min
}

const calculYiq = function (hexa) {
  if (hexa === null) {
    return '#000'
  }

  if (hexa === '#fff') {
    return '#000'
  }

  if (hexa === '#000') {
    return '#fff'
  }

  const r = parseInt(hexa.substr(1, 2), 16)
  const g = parseInt(hexa.substr(3, 2), 16)
  const b = parseInt(hexa.substr(5, 2), 16)

  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000

  return (yiq >= 128) ? '#000' : '#fff'
}

const pngToCanvas = function (image, id) {
  var canvas = document.createElement('canvas')
  canvas.width = 32
  canvas.id = 'canvas-marker-' + id
  canvas.height = 32
  canvas.getContext('2d').drawImage(image, 0, 0)
  $(document.body).append(canvas)
  // console.log(canvas, image)

  return canvas
}

const hexToRgb = function (hex) {
  if (hex === null) {
    return null
  }
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b
  })

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null
}

const getCookie = function (name) {
  var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'))

  if (match) return match[2]

  return false
}

const setCookie = function (name, value, days) {
  var expires = ''
  if (days) {
    var date = new Date()
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
    expires = '; expires=' + date.toUTCString()
  }
  const secureCookie = !tools.isDev() ? 'secure' : ''

  document.cookie = name + '=' + (value || '') + expires + '; path=/; ' + secureCookie
}

const deleteCookie = function (name) {
  tools.setCookie(name, 'expired', -1)
}

const appendCookieContainer = function (cookies, container) {
  const prefsContainerId = 'gdpr-prefs-container-' + tools.getRandomInt()
  let cStart = '<div class="gdpr-prefs-container" id="' + prefsContainerId + '">'

  const $gdprCookieContainer = $('body .gdprcookie')
  const gdprCookieContainerId = $gdprCookieContainer.attr('id')

  const gdprConsent = tools.getCookie('eu-consent')

  const acceptAll = gdprConsent === 'accept-all'

  for (var i = 0; i < cookies.length; i++) {
    const category = cookies[i]

    let catContainer = ''

    catContainer += `
        <div class="p-2">
            <h2>` + category.title + `
        `
    const containerRef = tools.slugify(category.title) + '-' + tools.getRandomInt()
    const cookieName = tools.slugify(category.title)
    const hasAlreadyCookie = tools.getCookie(cookieName)

    if (category.required) {
      catContainer += `
                <div class="float-right">
                    <button class="btn btn-outline-success gdpr-choice-btn accept active btn-sm p-1 is-required" data-value="1" data-reference="` + containerRef + `" data-cookie-name="` + cookieName + `"><i class="fa fa-check-circle"></i> Accepter</button>
                </div>
            `
    } else {
      catContainer += `
                <div class="float-right">
            `
      let isActive = hasAlreadyCookie === true
      isActive = acceptAll === true ? true : isActive
      let activeNameClass = isActive ? 'active' : ''

      catContainer += `
                <button class="btn btn-outline-success gdpr-choice-btn accept btn-sm p-1 ` + containerRef + ` ` + activeNameClass + `" data-value="1" data-reference="` + containerRef + `" data-cookie-name="` + cookieName + `"><i class="fa fa-check-circle"></i> Accepter</button>
            `

      activeNameClass = !isActive ? 'active' : ''

      catContainer += `
                <button class="btn btn-outline-danger gdpr-choice-btn cancel btn-sm p-1 ` + containerRef + ` ` + activeNameClass + `" data-value="0" data-reference="` + containerRef + `" data-cookie-name="` + cookieName + `"><i class="fa fa-times-circle"></i> Refuser</button>
            `

      catContainer += `
                </div>
            `
    }

    catContainer += `
            </h2>
            <p>` + category.description + `</p>
        `

    if (category.cookies && category.cookies.length > 0) {
      for (let j = 0; j < category.cookies.length; j++) {
        const cookieOnThisCategory = category.cookies[j]
        const cookieCategoryName = cookieOnThisCategory.name
        const cookieCategoryReference = tools.slugify(cookieCategoryName)
        const cookieCategoryIsRegistred = tools.getCookie(cookieCategoryReference)

        let cookieCategoryContainer = `
                    <div class="gdpr-cookies-category">
                        <h3>` + cookieCategoryName + `
                `
        cookieCategoryContainer += `
                    <div class="float-right">
                `
        let cookieCategoryIsActive = cookieCategoryIsRegistred === true
        cookieCategoryIsActive = acceptAll === true ? true : cookieCategoryIsActive
        let cookieCategoryActiveNameClass = cookieCategoryIsActive ? 'active' : ''

        cookieCategoryContainer += `
                    <button
                    class="btn btn-outline-success gdpr-choice-btn accept btn-sm p-1 ` + cookieCategoryReference + ` ` + cookieCategoryActiveNameClass + `"
                    data-value="1" data-reference="` + cookieCategoryReference + `"
                    data-cookie-name="` + cookieCategoryReference + `"><i class="fa fa-check-circle"></i> Accepter</button>
                `

        cookieCategoryActiveNameClass = !cookieCategoryIsActive ? 'active' : ''

        cookieCategoryContainer += `
                    <button class="btn btn-outline-danger gdpr-choice-btn cancel btn-sm p-1 ` + cookieCategoryReference + ` ` + cookieCategoryActiveNameClass + `"
                    data-value="0"
                    data-reference="` + cookieCategoryReference + `"
                    data-cookie-name="` + cookieCategoryReference + `"><i class="fa fa-times-circle"></i> Refuser</button>
                `

        cookieCategoryContainer += `
                    </div>
                    </h3>
                    <p>` + cookieOnThisCategory.description + `</p>
                    </div>
                `

        catContainer += cookieCategoryContainer
      }
    }

    catContainer += `</div>`

    cStart += catContainer
  }

  cStart += '</div>'

  container.append(cStart)

  window.addEventListener('click', function (e) {
    console.log('waddev click')
    const currentModal = document.getElementById(gdprCookieContainerId)
    if (currentModal === undefined) {
      return
    }
    if (!currentModal.contains(e.target)) {
      tools.collectCookies(true)
    }
  })

  $('body').on('click', '.gdpr-choice-btn', function () {
    const $btn = $(this)
    const reference = $btn.data('reference')
    $('body .' + reference).removeClass('active')
    $btn.addClass('active')
  })
}

const cookiePrefsInit = function () {
  let $gdprCookieContainer = $('body .gdprcookie')
  if ($gdprCookieContainer.length > 0) {
    $gdprCookieContainer.show()
    $('html, body').animate({ scrollTop: 0 }, 'slow')
    return
  }
  const route = Routing.generate('app_bandeau')
  $.get({
    url: route,
    success: function (response) {
      $('html, body').animate({ scrollTop: 0 }, 'slow')
      $.gdprcookie.init({
        title: 'Panneau de gestion des cookies',
        subtitle: 'Sélectionner les cookies à conserver',
        message: response.message,
        delay: 500,
        expires: 30,
        cookieName: 'eu-consent',
        acceptReload: false,
        acceptBtnLabel: 'Tout accepter',
        advancedBtnLabel: 'Personnaliser les cookies',
        customShowMessage: function () {
          $gdprCookieContainer = $('body .gdprcookie')
          const gdprId = 'gdpr-' + tools.getRandomInt()
          $gdprCookieContainer.attr('id', gdprId)
          $gdprCookieContainer.append('<button class="gdpr-close btn btn-danger btn-sm font-size-11"><i class="fa fa-times"></i> Fermer</button>')
          const $c = $('body .gdprcookie-buttons')
          const $buttons = $c.children('button')
          $buttons.last().remove()
          $buttons.first().addClass('gdpr-headband-button accept-all btn btn-sm btn-success mr-2')
          $c.append('<button class="gdpr-headband-button cancel-all btn btn-sm btn-danger mr-2">Tout refuser</button>')
          $c.append('<a href="' + response.politicyUri + '">Politique de confidentialité</a>')
          $gdprCookieContainer.trigger('focus')
          $('body .gdprcookie-types').show()
          tools.appendCookieContainer(response.cookies, $('body .gdprcookie-types'))
        },
        cookieTypes: [],
        customHideMessage: function () {
          tools.collectCookies()
        }
      })
    }
  })
}

const cookieHeadband = function () {
  const route = Routing.generate('app_bandeau')
  $.get({
    url: route,
    success: function (response) {
      const bandeau = `
          <div class="gdpr-headband container-fluid position-fixed bg-white">
            <div class="row text-left">
              <div class="col-12">
                <p class="text-muted font-size-15">
                Ce site utilise des cookies, de fonctionnement, de debugging, de mesure d’audience, et vous donne le contrôle sur ce que vous souhaitez activer
                </p>
              </div>
              <div class="col-12">
                <button class="gdpr-headband-button accept-all mr-1 btn btn-success btn-sm">
                  <i class="fa fa-check-circle"></i>
                  Tout accepter
                </button>
                <button class="gdpr-headband-button cancel-all mr-1 btn btn-danger btn-sm">
                  <i class="fa fa-times-circle"></i>
                  Tout refuser
                </button>
                <button class="gdpr-headband-button gdpr-config mr-1 btn btn-secondary btn-sm">
                  <i class="fa fa-cogs"></i>
                  Personnaliser mes choix
                </button>
                <a href="` + response.politicyUri + `" target="_blank" rel="noreferer noopener"
                title="Consulter la page de politique de confidentialité dans un nouvel onglet ?">
                  <i class="fa fa-external-link"></i>
                  Politique de confidentialité
                </a>
              </div>
            </div>
          </div>
        `
      $('body').append(bandeau)
    }
  })
}

const closeCookieHeadband = function () {
  $('body .gdpr-headband').hide('slow')
}

const acceptAllCookies = function () {
  const $cancelCookieBtns = $('body .gdpr-choice-btn.cancel')
  const $acceptCookieBtns = $('body .gdpr-choice-btn.accept')

  // accept with headband button
  if ($acceptCookieBtns.length <= 0 && $cancelCookieBtns.length <= 0) {
    tools.setCookie('eu-consent', 'accept-all', 30)
    tools.closeCookieHeadband()
    return
  }

  $cancelCookieBtns.each(function (i, elm) {
    const $cancelCookieBtn = $(elm)
    $cancelCookieBtn.removeClass('active')
  })

  $acceptCookieBtns.each(function (i, elm) {
    const $acceptCookieBtn = $(elm)
    $acceptCookieBtn.addClass('active')
  })

  tools.collectCookies()
}

const cancelAllCookies = function () {
  const $cancelCookieBtns = $('body .gdpr-choice-btn.cancel')
  const $acceptCookieBtns = $('body .gdpr-choice-btn.accept')

  if ($acceptCookieBtns.length <= 0 && $cancelCookieBtns.length <= 0) {
    tools.setCookie('eu-consent', 'refuse-all', 30)
    tools.closeCookieHeadband()

    return
  }

  $cancelCookieBtns.each(function (i, elm) {
    const $cancelCookieBtn = $(elm)
    $cancelCookieBtn.addClass('active')
  })

  $acceptCookieBtns.each(function (i, elm) {
    const $acceptCookieBtn = $(elm)
    if ($acceptCookieBtn.hasClass('is-required')) {
      return
    }
    $acceptCookieBtn.removeClass('active')
  })

  tools.collectCookies()
}

const collectCookies = function (dismissModal = false) {
  const $cookiesButton = $('body .gdpr-choice-btn.active')

  tools.deleteCookie('eu-consent')

  $cookiesButton.each(function (i, elm) {
    const $btn = $(elm)

    const isRequired = $btn.hasClass('is-required')
    const isActive = $btn.hasClass('active')
    const value = $btn.data('value')
    const cookieName = $btn.data('cookie-name')

    tools.deleteCookie(cookieName)

    if (value === 1 && !isRequired && isActive) {
      tools.setCookie(cookieName, value, 30)
    }
  })

  if (dismissModal) {
    tools.closeCookiePanel()
  }

  tools.setCookie('eu-consent', 'personnalise', 30)
}

const closeCookiePanel = function () {
  $('body .gdprcookie').fadeOut('hide')
}

const slugify = function (string) {
  return string
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
}

const getCurrentStructureSlug = function () {
  return $('body').data('structure')
}

const isDev = function () {
  return $('body').hasClass('dev-mode')
}

const isDecimalNumber = function (number) {
  return (number - Math.floor(number)) !== 0
}

const setupMultiplePayments = function () {
  const $resultContainer = $('body .result-mult-pay')
  $resultContainer.html('')
  const $selectedRange = $('body .badge-mult-pay-range.active')
  const rangeValue = parseInt($selectedRange.data('range'))
  const $transactionInfosContainer = $('body .mult-pay-transaction-details')
  const transactionId = $transactionInfosContainer.data('transaction-id')
  const prevDay = $transactionInfosContainer.data('prev-day')
  const transactionMontant = parseFloat($transactionInfosContainer.data('montant')) / 100
  if (rangeValue === 1) {
    tools.showMultiplePaymentSimulation(prevDay, transactionMontant, 0, rangeValue, transactionMontant, transactionId)
    return
  }
  $('body .pay-mult-form').remove()
  // $resultContainer.append('<p class="mb-0">rangeValue => ' + rangeValue + '</p>')
  if (rangeValue <= 1 || rangeValue > 12 || isNaN(rangeValue)) {
    console.log('rangeValue is invalid => ' + rangeValue)
    return
  }

  const initialTransactionMontant = transactionMontant

  let parts = transactionMontant / rangeValue
  parts = parseFloat(parts.toFixed(2))

  let totalParts = parts * rangeValue
  totalParts = parseFloat(totalParts.toFixed(2))

  let diff = initialTransactionMontant - totalParts
  diff = parseFloat(diff.toFixed(2))

  let firstPayment = parts + diff
  firstPayment = parseFloat(firstPayment.toFixed(2))

  let totalAmount = firstPayment + (parts * (rangeValue - 1))
  totalAmount = parseFloat(totalAmount.toFixed(2))

  if (totalAmount !== initialTransactionMontant) {
    // $resultContainer.append('<p class="mb-0 font-weight-bold text-danger">indispo</p>')
    return
  }
  tools.showMultiplePaymentSimulation(prevDay, firstPayment, parts, rangeValue, totalAmount, transactionId)
  // $resultContainer.append('<p class="mb-0 font-weight-bold text-success">OK Premier paiement de ' + firstPayment + ' &euro; <br />Ensuite ' + (rangeValue-1) + 'x ' + parts + '&euro;</p>')
}

const showMultiplePaymentSimulation = function (prevDay, firstPayment, paymentParts, range, totalPayment, transactionId) {
  const $resultContainer = $('body .result-mult-pay')
  const currentDay = (new Date().getUTCDate() < 10) ? '0' + new Date().getUTCDate() : new Date().getUTCDate()
  let currentMonth = (new Date().getUTCMonth() + 1) // getUtcMonth begin at 0
  let tmpMonth = (currentMonth > 9) ? currentMonth : '0' + currentMonth
  const today = currentDay + '/' + tmpMonth + '/' + new Date().getUTCFullYear()
  let currentYear = new Date().getUTCFullYear()
  let tdsContent = ''
  for (var i = 1; i < range; i++) {
    currentMonth++
    if (currentMonth > 12) {
      currentMonth = 1
      currentYear++
    }
    tmpMonth = (currentMonth > 9) ? currentMonth : '0' + currentMonth
    const tmpPrevDay = prevDay > 9 ? prevDay : '0' + prevDay
    tdsContent += '<tr><td>' + paymentParts + ' &euro;</td><td>' + tmpPrevDay + '/' + tmpMonth + '/' + currentYear + '</td></tr>'
  }
  const fullyTable = `
        <table class="table table-striped">
            <thead>
                <th>Montant</th>
                <th>Date</th>
            </thead>
            <tbody>
                <tr>
                <td>` + firstPayment + ` &euro;</td><td>` + today + `</td>
                </tr>
                ` + tdsContent + `
                <tr>
                    <td class="text-right" colspan="2">Montant total : <b>` + totalPayment + ` &euro;</b></td>
                </tr>
            </tbody>
        </table>
    `
  $resultContainer.append(fullyTable)

  // create form
  const $formContainer = $('body .payment-actions-container')
  $('body .pay-mult-form').remove()
  if (range <= 1) {
    return
  }
  const formContent = `
        <form action="#" method="POST" class="pay-mult-form my-2">
            <input type="hidden" name="is-mult-form" value="` + transactionId + `">
            <input type="hidden" name="range" value="` + range + `">
            <input type="hidden" name="firstPayment" value="` + firstPayment + `">
            <input type="hidden" name="paymentParts" value="` + paymentParts + `">
            <input type="hidden" name="prevDay" value="` + prevDay + `">
            <input type="hidden" name="transactionId" value="` + transactionId + `">
            <button type="submit" role="button" class="btn btn-outline-facture">
                <i class="fa fa-credit-card"></i>
                Paiement échelonné en ` + range + `x
            </button>
        </form>
    `

  $formContainer.append(formContent)
}

const tools = {
  loadingWrapper,
  loadContent,
  init,
  calculYiq,
  confirm,
  updateBrowserHistory,
  numberWithSpace,
  generateRgba,
  autoloading,
  addToProcess,
  isInProcess,
  getCookie,
  setCookie,
  getRandomInt,
  pngToCanvas,
  hexToRgb,
  appendCookieContainer,
  slugify,
  collectCookies,
  deleteCookie,
  cookieHeadband,
  cookiePrefsInit,
  isDev,
  acceptAllCookies,
  cancelAllCookies,
  closeCookieHeadband,
  closeCookiePanel,
  isDecimalNumber,
  setupMultiplePayments,
  showMultiplePaymentSimulation,
  getCurrentStructureSlug
}

export default tools
