(function ($) {
  'use strict'

  // ============================== PREELOADER ==============================*/
  $(window).on('load', function () {
    $('#preloader').fadeOut(1000)
  })

  // ============================== NAVBAR ==============================*/
  $(window).on('load', function () {
    var headerArea = $('.header')
    var mainArea = headerArea.find('.navbar')
    var zero = 0
    var navbarSticky = $('.navbar-sticky')

    $(window).scroll(function () {
      var st = $(this).scrollTop()
      if (st > zero) {
        navbarSticky.addClass('navbar-scrollUp')
      } else {
        navbarSticky.removeClass('navbar-scrollUp')
      }
      zero = st

      if (mainArea.hasClass('navbar-sticky') && ($(this).scrollTop() <= 600 || $(this).width() <= 300)) {
        mainArea.removeClass('navbar-scrollUp')
        mainArea.removeClass('navbar-sticky').appendTo(headerArea)
        headerArea.css('height', 'auto')
      } else if (!mainArea.hasClass('navbar-sticky') && $(this).width() > 300 && $(this).scrollTop() > 600) {
        headerArea.css('height', headerArea.height())
        mainArea.addClass('navbar-scrollUp')
        mainArea.css({ opacity: '0' }).addClass('navbar-sticky')
        mainArea.appendTo($('body')).animate({ opacity: 1 })
      }
    })

    $(window).trigger('resize')
    $(window).trigger('scroll')
  })

  // ============================== Topbar Cart Icon =========================*/

  $('.cart-dropdown a').on('click', function () {
    $('.cart-dropdown a .icon-small').toggleClass('d-none')
  })

  // ============================== BACK TO TOP ==============================*/
  $(document).ready(function () {
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $('#back-to-top').css('opacity', 1)
      } else {
        $('#back-to-top').css('opacity', 0)
      }
    })
  })

  // ============================== SMOOTH SCROLLING TO SECTION (LIKE BACK TO TOP) ==============================*/
  $('.scrolling  a[href*="#"]').on('click', function (e) {
    e.preventDefault()
    e.stopPropagation()
    var target = $(this).attr('href')

    function customVelocity (setOffset) {
      $(target).velocity('scroll', {
        duration: 800,
        offset: setOffset,
        easing: 'easeOutExpo',
        mobileHA: false
      })
    }

    if ($('#body').hasClass('up-scroll') || $('#body').hasClass('static')) {
      customVelocity(2)
    } else {
      customVelocity(-90)
    }
  })
})(jQuery)
