import _ from 'lodash'
import helpers from './helpers'

/* global Routing FormData */

$(document).ready(function () {
  $('body').on('keyup', 'input[data-sensibility="true"]', function () {
    const $elm = $(this)
    let value = $(this).val()
    value = value.replace('\'', ' ')
    value = value.replace('/', '')
    value = value.replace('\\', '')
    value = value.replace('&', '')
    value = value.replace('"', ' ')
    value = value.toUpperCase()
    value = _.deburr(value)
    $elm.val(value)
  })
  $('body').on('blur', 'input[data-sensibility="true"]', function () {
    const $elm = $(this)
    let value = $(this).val()
    value = value.replace('\'', ' ')
    value = value.replace('/', '')
    value = value.replace('\\', '')
    value = value.replace('&', '')
    value = value.replace('"', ' ')
    value = value.toUpperCase()
    value = _.deburr(value)
    $elm.val(value)
  })

  // Listener suivi de document when import files
  $('body').on('change', '.module-suivi-de-document.files', function () {
    const $input = $(this)
    const formReference = $input.data('reference')
    const files = $input[0].files
    const form = document.getElementById(formReference)

    if (files.length > 5) {
      htmlInfos('<i class="fa fa-exclamation-circle text-danger"></i> Attention, vous ne pouvez déposer que 5 fichiers en même temps pour un même document')

      return
    }

    const isMultiple = (files.length > 1)
    htmlInfos('')

    const formData = new FormData(form)

    // * Si l'on trouve un de ces caractères alors le fichier n'est pas valide
    // if (/[/<>:"\\|?*]/.test(files[0].name)) {
    //   htmlInfos('<i class="fa fa-exclamation-circle text-danger"></i> Un fichier ne doit pas contenir les caractères suivants / < > : " \\ | ? *, merci de renommer votre fichier en supprimant ces caractères.<br />')
    //   return
    // }

    const route = Routing.generate('app_suividedocument_uploads', {
      st_slug: $('body').data('structure')
    })

    let tempTxt = isMultiple ? 'Vos documents sont' : 'Votre document est'
    htmlInfos('<i class="fa fa-spinner fa-spin text-info"></i> ' + tempTxt + ' en cours d\'analyse, merci pour votre patience')

    $.post({
      url: route,
      data: formData,
      contentType: false,
      processData: false,
      success: function (response) {
        if (response.state !== true) {
          htmlInfos('<i class="fa fa-exclamation-circle text-danger"></i> Une ou plusieurs erreurs détectées : <br />' + response.error)

          return
        }

        tempTxt = isMultiple ? 'vos documents' : 'votre document'

        htmlInfos('<i class="fa fa-check-circle text-success"></i> La transmission de ' + tempTxt + ' s\'est bien déroulée, vous recevrez une réponse de votre structure prochaînement')

        if ($('body #' + $('.btn-url.btn-outline-family.active').data('container')) === undefined) {
          return
        }

        helpers.loadContent($('body #' + $('.btn-url.btn-outline-family.active').data('container')), $('.btn-url.btn-outline-family.active').data('url'))
      }
    })
  })

  // Listener for submitted form with ajax
  $('body').on('submit', 'form.jx-form', function (e) {
    e.preventDefault()
    const $form = $(this)
    const formId = $form.attr('id')

    if (formId === undefined) {
      return
    }

    const form = document.getElementById(formId)
    const urlToPost = $form.data('url')
    const formData = new FormData(form)

    $.post({
      url: urlToPost,
      data: formData,
      contentType: false,
      processData: false,
      success: function (rsp) {
        if (rsp.state === undefined) {
          return
        }

        const msg = (rsp.msg !== undefined) ? rsp.msg : (rsp.state === true) ? 'Les changements ont bien été appliqués' : 'La demande n\'a pu aboutir'
        const headerClass = rsp.state === true ? 'bg-success' : 'bg-danger'
        helpers.displayNotification('Mise à jour réussie', msg, { headerClass: headerClass })
      }
    })
  })

  // toggle content based on switch
  $('body').on('change', '.toggle-content', function () {
    const $toggle = $(this)
    const target = $toggle.data('target')
    const toOpen = ($toggle.prop('checked') === 1)

    if (toOpen) {
      $('.' + target).slideDown('slow')
      return
    }

    $('.' + target).slideUp('slow')
  })

  $('body').on('change', '.toggle-rubrique-finance', function () {
    const $toggle = $(this)
    const isChecked = $toggle.is(':checked')
    if (!isChecked) {
      $('body #portail_hasAttestationFiscale').bootstrapToggle('off')
      $('body #portail_rubriqueReglement').bootstrapToggle('off')
      $('body #portail_hasRubriqueFacture').bootstrapToggle('off')
    } else {
      $('body #portail_hasAttestationFiscale').bootstrapToggle('on')
      $('body #portail_rubriqueReglement').bootstrapToggle('on')
      $('body #portail_hasRubriqueFacture').bootstrapToggle('on')
    }
  })
})

function appendInfos (str) {
  const $container = $('body #infos')

  if ($container.length <= 0) {
    return
  }

  const wrapper = `
    <div class="col-12 mb-1 font-weight-bold">
      ` + str + `
    </div>
  `

  setTimeout(function () {
    $container.hide('slow')
  }, 5000)
  $container.append(wrapper)

  if (!$container.is(':visible')) {
    $container.show('slow')
  }
}

function htmlInfos (str) {
  const $container = $('body #infos')

  if ($container.length <= 0) {
    return
  }

  const wrapper = `
    <div class="col-12 py-2 font-weight-bold">
      ` + str + `
    </div>
  `

  setTimeout(function () {
    $container.hide('slow')
  }, 5000)

  $container.html(wrapper)

  if (!$container.is(':visible')) {
    $container.show('slow')
  }
}
